import React from "react";

class IwaLead extends React.Component {
  render() {
    return (
      <div
        style={{ minHeight: 554 }}
        id="iwa-widget"
        data-key={this.props.iwaKey}
      />
    );
  }
  componentDidMount() {
    /* eslint-disable */
    var width800 = 800;
    var width802 = 802;
    var width816 = 816;
    var pad32 = 32;
    var pad16 = 16;
    var height552 = 552;
    var switchedToMobile = 0;
    var iwahttpPref = "http:";
    if ((document["URL"] + "")["indexOf"]("https:") > -1) {
      iwahttpPref = "https:";
    }
    var iwapluginPath =
      iwahttpPref + "//www.immobilienwertanalyse.de/iwalead/plugin";
    var iwawasresized = 0;
    var iwaiframeInitialHeight = -1;
    var iwaiframeInitialWidth = -1;
    var iwaColorBox = -1;
    var iwaIFrame = -1;
    var iwaDataKey = "";
    var iwaWidgetScale = 1;
    var iwaIframeMaxWidth = width802;
    var iwaMobileMaxWidth = -1;
    var iwaWidgetLayoutStyle = 0;
    var isIWAMobile = false;
    var preventIWAResize = false;
    var isPageReady = 0;
    var triedonload = 0;

    function loadIWAjQuery() {
      var _0x6469x19 = document["getElementById"]("iwa-widget");
      if (_0x6469x19 === null) {
        if (triedonload == 1) {
          return;
        }
        // window['onload'] = function() {
        // 	triedonload = 1;
        // 	loadIWAjQuery()
        // }
      } else {
        iwaDataKey = _0x6469x19["getAttribute"]("data-key");
      }
      if (
        (iwaDataKey == "TWZ569IK11LVC886HR41" ||
          iwaDataKey == "UNB764II18JNA419HY85") &&
        isPageReady == 0
      ) {
        window["onload"] = function () {
          var _0x6469x1a = document["getElementsByTagName"]("script");
          var _0x6469x1b = 0;
          for (
            var _0x6469x1c = 0;
            _0x6469x1c < _0x6469x1a["length"];
            _0x6469x1c++
          ) {
            var _0x6469x1d = _0x6469x1a[_0x6469x1c]["src"];
            if (_0x6469x1d["indexOf"]("jquery") > -1) {
              _0x6469x1b = 1;
              break;
            }
          }
          isPageReady = 1;
          if (_0x6469x1b == 1) {
            loadFiles();
          } else {
            loadIWAjQuery();
          }
        };
      } else {
        if (!window["jQuery"]) {
          var _0x6469x1e = document["getElementsByTagName"]("head")[0];
          var _0x6469x1f = document["createElement"]("script");
          _0x6469x1f["type"] = "text/javascript";
          _0x6469x1f["src"] = iwapluginPath + "/js/jquery-1.11.1.min.js";
          _0x6469x1f["onload"] = function () {
            loadFiles();
          };
          _0x6469x1e["appendChild"](_0x6469x1f);
        } else {
          loadFiles();
        }
      }
    }
    var docHead;

    function loadFiles() {
      docHead = document["getElementsByTagName"]("head")[0];
      var _0x6469x22 = new Date()["getTime"]();
      var _0x6469x23 = document["createElement"]("script");
      _0x6469x23["type"] = "text/javascript";
      _0x6469x23["src"] = iwapluginPath + "/js/iwapopup.js?" + _0x6469x22;
      _0x6469x23["onload"] = function () {
        getIWAUserWidget();
      };
      if (
        iwaDataKey == "AMI246FF89THK414SO46" ||
        iwaDataKey == "ECV572FR94PTK778UV39" ||
        iwaDataKey == "VDS966IY49JDV199JE25"
      ) {
        window["onload"] = function () {
          document["body"]["appendChild"](_0x6469x23);
        };
      } else {
        docHead["appendChild"](_0x6469x23);
      }
      loadCssFile("css/iwapopup.css");
      loadCssFile("css/widget.css");
    }

    function initIWAUserWidget(_0x6469x25, _0x6469x26, _0x6469x27) {
      (function (_0x6469x28) {
        var _0x6469x29 = _0x6469x28("#iwa-widget");
        _0x6469x29["html"](_0x6469x26);
        iwaWidgetLayoutStyle = _0x6469x25;
        if (iwaWidgetLayoutStyle == 3) {
          _0x6469x28("#iwa-widget-iframe")["css"](
            "width",
            iwaIframeMaxWidth + "px"
          );
          _0x6469x28("#iwa-widget-iframe")["css"](
            "height",
            (554 / width802) * iwaIframeMaxWidth + "px"
          );
          _0x6469x29["css"]("width", iwaIframeMaxWidth + "px");
          _0x6469x29["css"](
            "height",
            (554 / width802) * iwaIframeMaxWidth + "px"
          );
          _0x6469x29["css"]("margin", "0px auto");
          resizeIWAIFrame();
          _0x6469x29["css"]("z-index", 999999);
          _0x6469x28("#iwa-widget-iframe")["css"]("z-index", 999999);
        } else {
          var _0x6469x2a = width816;
          var _0x6469x2b = height552 + pad16 + pad32;
          if (iwaWidgetLayoutStyle == 2 || _0x6469x27 > 0) {
            var _0x6469x2c = window["location"]["href"] + " ";
            if (
              _0x6469x2c["indexOf"]("immobilienwertanalyse") > -1 &&
              (_0x6469x2c["indexOf"]("config") > -1 ||
                _0x6469x2c["indexOf"]("connect") > -1)
            ) {
            } else {
              _0x6469x28("#iwa-widget")["appendTo"](document["body"]);
            }
            preventIWAResize = true;
          }
          if (isIWAMobile) {
            if (_0x6469x2a > window["innerWidth"]) {
              _0x6469x2b = (window["innerWidth"] / _0x6469x2a) * _0x6469x2b;
              _0x6469x2a = window["innerWidth"];
            }
            if (_0x6469x2b > window["innerHeight"]) {
              _0x6469x2a = (window["innerHeight"] / _0x6469x2b) * _0x6469x2a;
              _0x6469x2b = window["innerHeight"];
            }
            resizeIWAWidget();
          } else {
            if (
              iwaDataKey == "RPW336FK15UWC999RA15" &&
              iwaWidgetLayoutStyle == 0
            ) {
              resizeIWAWidgetFunc(350);
            } else {
              if (
                iwaDataKey == "WPU437BW26ZPV123CO49" &&
                iwaWidgetLayoutStyle == 0
              ) {
                resizeIWAWidgetFunc(385);
              }
            }
          }
          var _0x6469x2d =
            "iwawidget_closed_" + iwaWidgetLayoutStyle + "_" + _0x6469x27;
          if (
            getCookie(_0x6469x2d) == 1 &&
            _0x6469x29["attr"]("data-config") != 1
          ) {
            _0x6469x28(".iwabasic-widget1")["addClass"]("closed");
          }
          _0x6469x28(".iwabasic-widget1 .iwabasic-widget-hide")["click"](
            function () {
              if (_0x6469x28(".iwabasic-widget1")["hasClass"]("closed")) {
                _0x6469x28(".iwabasic-widget1")["removeClass"]("closed");
                if (_0x6469x29["attr"]("data-config") != 1) {
                  setCookie(_0x6469x2d, 0, 1);
                }
              } else {
                _0x6469x28(".iwabasic-widget1")["addClass"]("closed");
                if (_0x6469x29["attr"]("data-config") != 1) {
                  setCookie(_0x6469x2d, 1, 1);
                }
              }
            }
          );
          _0x6469x28(".iwabasic-widget1 .iwabasic-widget-title")["click"](
            function () {
              _0x6469x28(".iwabasic-widget1")["removeClass"]("closed");
            }
          );
          _0x6469x28(".iwa-lightbox-link")["iwacolorbox"]({
            iframe: true,
            width: _0x6469x2a + "px",
            height: _0x6469x2b + "px",
            scrolling: false,
            opacity: 0.7,
            isMobile: isIWAMobile,
            reposition: !isIWAMobile,
            showDebug:
              _0x6469x28("#iwa-widget")["attr"]("data-key") == "AABBCCT",
            fadeOutOnClose:
              _0x6469x28("#iwa-widget")["attr"]("data-key") !=
              "AMI246FF89THK414SO46",
            onComplete: function () {
              if (isIWAMobile) {
                _0x6469x28("#iwacboxOverlay")["css"]("width", "10000px");
                _0x6469x28("#iwacboxOverlay")["css"]("heigth", "10000px");
              }
              iwaColorBox = _0x6469x28("#iwacolorbox");
              iwaIFrame = _0x6469x28(".iwacboxIframe");
              resizeIWAWindow();
              if (isIWAMobile) {
                _0x6469x28("#iwacboxLoadedContent")["on"](
                  "scroll",
                  function (_0x6469x2e) {
                    if (
                      _0x6469x28("#iwacboxLoadedContent")["scrollTop"]() != 0
                    ) {
                      _0x6469x28("#iwacboxLoadedContent")["scrollTop"](0);
                    }
                  }
                );
              }
            },
          });
        }
      })(jQuery);
    }

    function getCookie(_0x6469x30) {
      var _0x6469x31 = _0x6469x30 + "=";
      var _0x6469x32 = decodeURIComponent(document["cookie"]);
      var _0x6469x33 = _0x6469x32["split"](";");
      for (
        var _0x6469x1c = 0;
        _0x6469x1c < _0x6469x33["length"];
        _0x6469x1c++
      ) {
        var _0x6469x34 = _0x6469x33[_0x6469x1c];
        while (_0x6469x34["charAt"](0) == " ") {
          _0x6469x34 = _0x6469x34["substring"](1);
        }
        if (_0x6469x34["indexOf"](_0x6469x31) == 0) {
          return _0x6469x34["substring"](
            _0x6469x31["length"],
            _0x6469x34["length"]
          );
        }
      }
      return "";
    }

    function setCookie(_0x6469x30, _0x6469x36, _0x6469x37) {
      var _0x6469x38 = new Date();
      _0x6469x38["setTime"](
        _0x6469x38["getTime"]() + _0x6469x37 * 24 * 60 * 60 * 1000
      );
      var _0x6469x39 = "expires=" + _0x6469x38["toUTCString"]();
      try {
        document["cookie"] =
          _0x6469x30 + "=" + _0x6469x36 + ";" + _0x6469x39 + ";path=/";
      } catch (e) {}
    }

    function getIWAUserWidget() {
      (function (_0x6469x28) {
        var _0x6469x29 = _0x6469x28("#iwa-widget");
        iwaDataKey = _0x6469x29["attr"]("data-key");
        if (iwaDataKey == "AABBCCT") {
          iwaDataKey = "AABBCC";
        }
        var _0x6469x3b = _0x6469x29["attr"]("data-param");
        if (_0x6469x3b === undefined) {
          _0x6469x3b = "";
        }
        if (_0x6469x29["attr"]("data-max-width") !== undefined) {
          iwaIframeMaxWidth = _0x6469x29["attr"]("data-max-width");
        }
        var _0x6469x3c = -1;
        if (_0x6469x29["attr"]("data-start-index") !== undefined) {
          _0x6469x3c = _0x6469x29["attr"]("data-start-index");
        }
        isIWAMobile = isIwaMobileDevice();
        var _0x6469x3d = isMobileTablet();
        if (isIWAMobile && !_0x6469x3d) {
          setMobileSizeVars();
        }
        if (
          _0x6469x29["attr"]("data-mobile-width") !== undefined &&
          iwaMobileMaxWidth > _0x6469x29["attr"]("data-mobile-width")
        ) {
          iwaMobileMaxWidth = _0x6469x29["attr"]("data-mobile-width");
        }
        var _0x6469x3e =
          navigator["userAgent"] +
          " | " +
          navigator["vendor"] +
          " | " +
          window["opera"];
        var _0x6469x3f = window["innerWidth"] + " x " + window["innerHeight"];
        _0x6469x28["ajax"]({
          url: iwapluginPath + "/ajax/getUserWidget.php",
          data: {
            userKey: iwaDataKey,
            widgetConfig: _0x6469x29["attr"]("data-config"),
            url: window["location"]["href"],
            dataParam: _0x6469x3b,
            isMobile: isIWAMobile,
            userInfo: _0x6469x3e,
            deviceSize: _0x6469x3f,
            sx: _0x6469x3c,
            isTablet: _0x6469x3d,
          },
          type: "post",
          statusCode: {
            503: function () {},
          },
          success: function (_0x6469x40) {
            var _0x6469x41;
            try {
              _0x6469x41 = JSON["parse"](_0x6469x40);
            } catch (err) {
              _0x6469x41 = _0x6469x40;
            }
            initIWAUserWidget(
              _0x6469x41["style"]["layoutType"],
              _0x6469x41["html"],
              _0x6469x41["style"]["layoutPos"]
            );
            var _0x6469x42 =
              _0x6469x28(".iwa-lightbox-link")["iwacolorbox"]["close"];
            _0x6469x28(".iwa-lightbox-link")["iwacolorbox"]["close"] =
              function () {
                if (
                  confirm(
                    unescape(
                      "Sind Sie sicher%2C dass Sie das Fenster schlie%DFen m%F6chten%3F"
                    )
                  )
                ) {
                  iwaColorBox = -1;
                  _0x6469x42();
                }
              };
            var _0x6469x43 = _0x6469x41["style"];
            if (_0x6469x43 && _0x6469x43 != "") {
              setIWAStyle(
                _0x6469x28,
                _0x6469x43["layoutType"],
                _0x6469x43["layoutPos"],
                _0x6469x43["layoutImage"],
                _0x6469x43["layoutStartPage"],
                _0x6469x43["roundCorners"],
                _0x6469x43["buttonColor"],
                _0x6469x43["barColor"],
                _0x6469x43["textColor"],
                _0x6469x43["buttontextColor"]
              );
            }
          },
        });
      })(jQuery);
    }
    window["addEventListener"](
      "orientationchange",
      onIWAOrientationChange,
      false
    );

    function setMobileSizeVars(_0x6469x45) {
      pad32 = 32;
      pad16 = 16;
      if (_0x6469x45 === undefined || _0x6469x45 != 1) {
        width800 = 346;
        width802 = 348;
        height552 = 550;
        iwaIframeMaxWidth = 348;
      } else {
        width800 = 800;
        width802 = 802;
        height552 = 552;
        iwaIframeMaxWidth = width802;
      }
      width816 = width800 + pad16;
    }

    function loadCssFile(_0x6469x47) {
      var _0x6469x22 = new Date()["getTime"]();
      var _0x6469x48 = document["createElement"]("link");
      _0x6469x48["setAttribute"]("rel", "stylesheet");
      _0x6469x48["setAttribute"]("type", "text/css");
      if (_0x6469x47["indexOf"]("http") > -1) {
        _0x6469x48["setAttribute"]("href", _0x6469x47);
      } else {
        _0x6469x48["setAttribute"](
          "href",
          iwapluginPath + "/" + _0x6469x47 + "?" + _0x6469x22
        );
      }
      docHead["appendChild"](_0x6469x48);
    }

    function loadJsFile(_0x6469x47) {
      var _0x6469x22 = new Date()["getTime"]();
      var _0x6469x4a = document["createElement"]("script");
      _0x6469x4a["type"] = "text/javascript";
      if (_0x6469x47["indexOf"]("http") > -1) {
        _0x6469x4a["src"] = _0x6469x47;
      } else {
        _0x6469x4a["src"] = iwapluginPath + "/" + _0x6469x47 + "?" + _0x6469x22;
      }
      docHead["appendChild"](_0x6469x4a);
    }

    function onIWAOrientationChange() {
      (function (_0x6469x28) {
        if (iwaWidgetLayoutStyle == 3) {
          resizeIWAIFrame();
        } else {
          if (iwaWidgetLayoutStyle == 0 || iwaWidgetLayoutStyle == 1) {
            resizeIWAWidget();
          }
        }
        if (_0x6469x28("#iwacolorbox")["is"](":visible") && iwaColorBox != -1) {
          setTimeout(function () {
            resizeIWAWindow();
          }, 300);
        }
      })(jQuery);
    }
    window["addEventListener"]("resize", onIWAresizeFunc, false);

    function onIWAresizeFunc() {
      if (iwaWidgetLayoutStyle == 3) {
        resizeIWAIFrame(1);
      }
    }

    function resizeIWAIFrame(_0x6469x4e) {
      (function (_0x6469x28) {
        var _0x6469x4f = _0x6469x28("#iwa-widget")["parent"]()["width"]();
        if (iwaiframeInitialHeight == -1) {
          iwaiframeInitialWidth = width802 + "px";
          iwaiframeInitialHeight = "554px";
        }
        var _0x6469x50 = document["getElementById"]("iwa-widget-iframe");
        var _0x6469x2a = width802;
        var _0x6469x2b = 554;
        var _0x6469x51 = 1;
        var _0x6469x52 = parseInt(iwaiframeInitialWidth["replace"]("px", ""));
        var _0x6469x53 = parseInt(iwaiframeInitialHeight["replace"]("px", ""));
        if (_0x6469x4f < height552 + pad16 + pad32 && !isIWAMobile) {
          _0x6469x4f = height552 + pad16 + pad32;
        }
        if (_0x6469x51 > _0x6469x4f / _0x6469x52) {
          _0x6469x51 = _0x6469x4f / _0x6469x52;
        }
        if (iwaIframeMaxWidth / _0x6469x52 < _0x6469x51) {
          _0x6469x51 = iwaIframeMaxWidth / _0x6469x52;
        }
        if (_0x6469x51 > 1) {
          _0x6469x51 = 1;
        }
        if (_0x6469x51 < 0.4) {
          _0x6469x51 = 0.4;
        }
        if (_0x6469x51 < 0.98 || iwawasresized == 1) {
          _0x6469x2a = _0x6469x52 * _0x6469x51 + pad16;
          _0x6469x2b = _0x6469x53 * _0x6469x51 + pad16 + pad32;
          iwawasresized = 1;
          _0x6469x50["style"]["height"] = iwaiframeInitialHeight;
          _0x6469x50["style"]["width"] = iwaiframeInitialWidth;
          _0x6469x50["style"]["MozTransform"] = "scale(" + _0x6469x51 + ")";
          _0x6469x50["style"]["MozTransformOrigin"] = "0 0";
          _0x6469x50["style"]["OTransform"] = "scale(" + _0x6469x51 + ")";
          _0x6469x50["style"]["OTransformOrigin"] = "0 0";
          _0x6469x50["style"]["WebkitTransform"] = "scale(" + _0x6469x51 + ")";
          _0x6469x50["style"]["WebkitTransformOrigin"] = "0 0";
        }
      })(jQuery);
    }

    function getIWAElementOffset(_0x6469x55) {
      var _0x6469x56 = 0;
      var _0x6469x57 = 0;
      while (
        _0x6469x55 &&
        !isNaN(_0x6469x55["offsetLeft"]) &&
        !isNaN(_0x6469x55["offsetTop"])
      ) {
        _0x6469x56 += _0x6469x55["offsetLeft"] - _0x6469x55["scrollLeft"];
        _0x6469x57 += _0x6469x55["offsetTop"] - _0x6469x55["scrollTop"];
        _0x6469x55 = _0x6469x55["offsetParent"];
      }
      return {
        top: _0x6469x57,
        left: _0x6469x56,
      };
    }

    function getIWADocumentWidth() {
      var _0x6469x59 =
        window["innerWidth"] ||
        document["documentElement"]["clientWidth"] ||
        document["body"]["clientWidth"];
      return _0x6469x59;
    }

    function getIWAWidgetOrigWidth() {
      if (iwaWidgetLayoutStyle == 0) {
        return 385 + 20 + 5;
      } else {
        if (iwaWidgetLayoutStyle == 1) {
          return 250 + 30 + 5;
        }
      }
    }

    function getMobileMaxWidthHelperIWA(_0x6469x28) {
      var _0x6469x5c = getIWADocumentWidth();
      var _0x6469x5d = getIWAWidgetOrigWidth();
      if (
        iwaDataKey == "KQQ535JD23SEN713CN43" &&
        _0x6469x5c < 480 &&
        _0x6469x5c > _0x6469x5d &&
        _0x6469x28("#boxed-layout")["length"]
      ) {
        _0x6469x5c = _0x6469x28("#boxed-layout")
          ["css"]("width")
          ["replace"]("px", "");
        if (_0x6469x5c < 200) {
          _0x6469x5c = 200;
        }
      }
      if (
        iwaDataKey == "UXB671OH76YVY438NT28" &&
        window["innerWidth"] < 768 &&
        _0x6469x28("#main-content")["length"]
      ) {
        _0x6469x5c = 290;
        if (_0x6469x28("#iwa-img-wrapper")["length"]) {
          _0x6469x5c =
            _0x6469x28("#main-content")["css"]("width")["replace"]("px", "") -
            70;
          if (_0x6469x5c > 290) {
            _0x6469x5c = 290;
          }
        }
        if (_0x6469x5c < 200) {
          _0x6469x5c = 200;
        }
      }
      return _0x6469x5c;
    }
    var tmpMarginLeft = -1;
    var tmpMarginTop = -1;

    function resizeIWAWidget() {
      (function (_0x6469x28) {
        if (iwaWidgetLayoutStyle > 1 || preventIWAResize) {
          return;
        }
        var _0x6469x5c = getMobileMaxWidthHelperIWA(_0x6469x28);
        var _0x6469x5d = getIWAWidgetOrigWidth();
        var _0x6469x29 = _0x6469x28("#iwa-widget");
        if (tmpMarginLeft == -1 && tmpMarginTop == -1) {
          tmpMarginLeft = _0x6469x29["css"]("margin-left");
          tmpMarginTop = _0x6469x29["css"]("margin-top");
        }
        if (iwaMobileMaxWidth > 50) {
          _0x6469x29["css"]("margin-left", tmpMarginLeft);
          _0x6469x29["css"]("margin-top", tmpMarginTop);
          if (
            iwaMobileMaxWidth < _0x6469x5c &&
            (iwaDataKey != "AABBCC" || window["innerWidth"] < 460)
          ) {
            _0x6469x5c = iwaMobileMaxWidth;
            if (iwaDataKey == "AABBCC") {
              var _0x6469x61 = (_0x6469x5d - iwaMobileMaxWidth) / 8;
              var _0x6469x62 = (_0x6469x5d - iwaMobileMaxWidth) / 2;
              _0x6469x29["css"]("margin-left", _0x6469x61 + "px");
              _0x6469x29["css"]("margin-top", _0x6469x62 + "px");
            }
          }
        }
        if (_0x6469x5c < _0x6469x5d && _0x6469x5c > 0) {
          var _0x6469x27 = _0x6469x29["position"]();
          var _0x6469x63 = getIWAElementOffset(_0x6469x29)["left"];
          var _0x6469x63 = _0x6469x63;
          if (_0x6469x63 < 0) {
            _0x6469x63 = 0;
          }
          iwaWidgetScale = _0x6469x5c / (_0x6469x5d + _0x6469x63);
          if (_0x6469x63 * 2 < _0x6469x5c) {
            iwaWidgetScale = (_0x6469x5c - _0x6469x63) / _0x6469x5d;
          }
          iwaWidgetScale = iwaWidgetScale * 0.9;
          resizeIWAWidgetFunc(-1, iwaWidgetScale);
          if (
            iwaDataKey == "UXB671OH76YVY438NT28" &&
            window["innerWidth"] < 768 &&
            _0x6469x28("#main-content")["length"] &&
            _0x6469x28("#iwa-img-wrapper")["length"]
          ) {
            _0x6469x29["css"]("-moz-transform-origin", "right bottom");
            _0x6469x29["css"]("-webkit-transform-origin", "right bottom");
            _0x6469x29["css"]("-o-transform-origin", "right bottom");
          } else {
            if (
              iwaDataKey == "A2HR4X38QK21JNO71AU8" &&
              window["innerWidth"] < 768
            ) {
              _0x6469x29["css"]("-moz-transform-origin", "right bottom");
              _0x6469x29["css"]("-webkit-transform-origin", "right bottom");
              _0x6469x29["css"]("-o-transform-origin", "right bottom");
            }
          }
        } else {
          if (iwaWidgetScale < 1) {
            resizeIWAWidgetFunc(-1, 1);
          }
        }
      })(jQuery);
    }

    function resizeIWAWidgetFunc(_0x6469x65, _0x6469x66) {
      (function (_0x6469x28) {
        if (iwaWidgetLayoutStyle > 1) {
          return;
        }
        var _0x6469x5c = _0x6469x65;
        var _0x6469x5d = getIWAWidgetOrigWidth();
        var _0x6469x29 = _0x6469x28("#iwa-widget");
        if (_0x6469x66 !== undefined) {
          iwaWidgetScale = _0x6469x66;
        } else {
          if (_0x6469x5c < _0x6469x5d && _0x6469x5c > 0) {
            iwaWidgetScale = _0x6469x5c / _0x6469x5d;
          } else {
            return;
          }
        }
        _0x6469x29["css"]("-moz-transform", "scale(" + iwaWidgetScale + ")");
        _0x6469x29["css"]("-moz-transform-origin", "0 0");
        _0x6469x29["css"]("-o-transform", "scale(" + iwaWidgetScale + ")");
        _0x6469x29["css"]("-o-transform-origin", "0 0");
        _0x6469x29["css"]("-webkit-transform", "scale(" + iwaWidgetScale + ")");
        _0x6469x29["css"]("-webkit-transform-origin", "0 0");
      })(jQuery);
    }
    var ratioBefore = -1;

    function resizeIWAWindow() {
      (function (_0x6469x28) {
        if (!isIwaMobileDevice()) {
          return;
        }
        if (iwaiframeInitialHeight == -1) {
          iwaiframeInitialHeight = _0x6469x28("iframe")["css"]("height");
          iwaiframeInitialWidth = _0x6469x28("iframe")["css"]("width");
          iwaiframeInitialWidth = width800 + "px";
          iwaiframeInitialHeight = height552 + "px";
        }
        var _0x6469x2a = width816;
        var _0x6469x2b = height552 + pad16 + pad32;
        var _0x6469x51 = 1;
        var _0x6469x69 = 1;
        var _0x6469x6a = 1;
        if (_0x6469x2a > window["innerWidth"]) {
          _0x6469x69 = (window["innerWidth"] * 0.95) / _0x6469x2a;
          _0x6469x51 = _0x6469x69;
        }
        if (_0x6469x2b + 20 > window["innerHeight"]) {
          _0x6469x6a = (window["innerHeight"] * 0.95) / (_0x6469x2b + 20);
          if (_0x6469x6a < _0x6469x69) {
            _0x6469x51 = _0x6469x6a;
          }
        }
        if (_0x6469x51 == 1) {
          if (_0x6469x2a < window["innerWidth"]) {
            _0x6469x69 = (window["innerWidth"] * 0.95) / _0x6469x2a;
            _0x6469x51 = _0x6469x69;
          }
          if (_0x6469x2b + 20 < window["innerHeight"]) {
            _0x6469x6a = (window["innerHeight"] * 0.95) / (_0x6469x2b + 20);
            if (_0x6469x6a < _0x6469x69) {
              _0x6469x51 = _0x6469x6a;
            }
          }
        }
        if (_0x6469x51 < 0.4) {
          _0x6469x51 = 0.4;
        }
        if (
          _0x6469x51 > 1.3 &&
          (navigator["userAgent"] + "")["indexOf"]("iPhone") > -1
        ) {
          _0x6469x51 = 1.3;
        }
        if (ratioBefore != _0x6469x51) {
          var _0x6469x52 = parseInt(iwaiframeInitialWidth["replace"]("px", ""));
          var _0x6469x53 = parseInt(
            iwaiframeInitialHeight["replace"]("px", "")
          );
          _0x6469x51 = Math["round"](_0x6469x51 * 10) / 10;
          _0x6469x2a = _0x6469x52 * _0x6469x51 + pad16;
          _0x6469x2b = _0x6469x53 * _0x6469x51 + pad16 + pad32;
          iwawasresized = 1;
          iwaIFrame["css"]("height", iwaiframeInitialHeight);
          iwaIFrame["css"]("width", iwaiframeInitialWidth);
          iwaIFrame["css"]("transform", "scale(" + _0x6469x51 + ")");
          iwaIFrame["css"](
            "-moz-transform",
            "translate3d(0,0,0) scale(" + _0x6469x51 + ")"
          );
          iwaIFrame["css"]("-moz-transform-origin", "0 0");
          iwaIFrame["css"](
            "-o-transform",
            "translate3d(0,0,0) scale(" + _0x6469x51 + ")"
          );
          iwaIFrame["css"]("-o-transform-origin", "0 0");
          iwaIFrame["css"](
            "-webkit-transform",
            "translate3d(0,0,0) scale(" + _0x6469x51 + ")"
          );
          iwaIFrame["css"]("-webkit-transform-origin", "0 0");
          var _0x6469x6b = 12 * _0x6469x51;
          if (_0x6469x6b < 8) {
            _0x6469x6b = 8;
          }
          if (_0x6469x6b > 24) {
            _0x6469x6b = 24;
          }
          _0x6469x28("#iwacboxClose")["css"](
            "background-size",
            _0x6469x6b + "px " + _0x6469x6b + "px"
          );
          _0x6469x28("#iwacboxClose")["css"](
            "background-position",
            30 - _0x6469x6b - 1 + "px " + (30 - _0x6469x6b - 6) + "px"
          );
          _0x6469x28(".iwa-lightbox-link")["iwacolorbox"]["resize"]({
            width: _0x6469x2a + "px",
            height: _0x6469x2b + "px",
          });
          setTimeout(function () {
            positionIWAPopup(_0x6469x2a);
          }, 250);
          ratioBefore = _0x6469x51;
        } else {
          positionIWAPopup(_0x6469x2a);
        }
      })(jQuery);
    }

    function positionIWAPopup(_0x6469x2a) {
      var _0x6469x6d = window["pageXOffset"];
      if (window["pageXOffset"] !== undefined) {
        _0x6469x6d = window["pageXOffset"];
      } else {
        _0x6469x6d = document["documentElement"]["scrollLeft"];
      }
      if (window["innerWidth"] > iwaColorBox["css"]("width")) {
        _0x6469x6d = parseInt(iwaColorBox["css"]("left")["replace"]("px", ""));
      }
      iwaColorBox["css"]({
        visibility: "visible",
      });
      _0x6469x6d = _0x6469x6d + (window["innerWidth"] - _0x6469x2a) / 2;
      iwaColorBox["css"]("left", _0x6469x6d + "px");
    }

    function setIWAStyle(
      _0x6469x6f,
      _0x6469x70,
      _0x6469x71,
      _0x6469x72,
      _0x6469x73,
      _0x6469x74,
      _0x6469x75,
      _0x6469x76,
      _0x6469x77,
      _0x6469x78
    ) {
      var _0x6469x75 = "#" + _0x6469x75;
      var _0x6469x79 = iwaHexToRgb(_0x6469x75);
      var _0x6469x7a = iwaRgbToHex(
        _0x6469x79["r"] - 25 < 0 ? 0 : _0x6469x79["r"] - 25,
        _0x6469x79["g"] - 25 < 0 ? 0 : _0x6469x79["g"] - 25,
        _0x6469x79["b"] - 25 < 0 ? 0 : _0x6469x79["b"] - 25
      );
      var _0x6469x7b = iwaRgbToHex(
        _0x6469x79["r"] + 12 > 255 ? 255 : _0x6469x79["r"] + 12,
        _0x6469x79["g"] + 12 > 255 ? 255 : _0x6469x79["g"] + 12,
        _0x6469x79["b"] + 12 > 255 ? 255 : _0x6469x79["b"] + 12
      );
      if (_0x6469x70 != 3) {
        var _0x6469x7c = ".iwabasic-widget1";
        if (_0x6469x70 == 2) {
          _0x6469x7c = ".iwabasic-widget2";
        }
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos");
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos1");
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos2");
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos3");
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos4");
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos5");
        _0x6469x6f(_0x6469x7c)["removeClass"]("fixpos6");
        _0x6469x6f(_0x6469x7c)["removeClass"]("closable");
        _0x6469x6f(_0x6469x7c)["removeClass"]("image1");
        _0x6469x6f(_0x6469x7c)["removeClass"]("image2");
        _0x6469x6f(_0x6469x7c)["removeClass"]("image3");
        _0x6469x6f(_0x6469x7c)["removeClass"]("image4");
        if (_0x6469x71 > 0 || _0x6469x70 == 2) {
          _0x6469x6f(_0x6469x7c)["addClass"]("fixpos");
          _0x6469x6f(_0x6469x7c)["addClass"]("closable");
          if (_0x6469x70 < 2) {
            _0x6469x6f(_0x6469x7c)["addClass"]("fixpos" + _0x6469x71);
          }
        }
        _0x6469x6f(_0x6469x7c)["addClass"]("image" + _0x6469x72);
        _0x6469x6f(_0x6469x7c + " .iwabasic-btn")["css"](
          "background-color",
          _0x6469x75
        );
        _0x6469x6f(_0x6469x7c + " .iwabasic-btn:hover")["css"](
          "background-color",
          _0x6469x7b
        );
        _0x6469x6f(_0x6469x7c + " .iwabasic-btn")["css"](
          "border-color",
          _0x6469x7a
        );
        _0x6469x6f(_0x6469x7c + " .iwabasic-widget-title")["css"](
          "background-color",
          _0x6469x75
        );
        _0x6469x6f(_0x6469x7c + ".type2")["css"](
          "background-color",
          _0x6469x75
        );
        _0x6469x6f(_0x6469x7c + " .iwabasic-btn")["hover"](function (
          _0x6469x2e
        ) {
          _0x6469x6f(this)["css"](
            "background-color",
            _0x6469x2e["type"] === "mouseenter" ? _0x6469x7b : _0x6469x75
          );
        });
        _0x6469x6f(".iwabasic-widget2")["css"](
          "background-color",
          "#" + _0x6469x76
        );
        _0x6469x6f(".iwabasic-widget2 .iwabasic-headline")["css"](
          "color",
          "#" + _0x6469x77
        );
        _0x6469x6f(".iwabasic-widget2 .iwabasic-btn")["css"](
          "color",
          "#" + _0x6469x78
        );
        if (_0x6469x70 == 2) {
          _0x6469x6f(_0x6469x7c + " .iwabasic-btn")["css"](
            "border-radius",
            "5px"
          );
          _0x6469x6f(_0x6469x7c)["css"]("border-radius", "0px");
        } else {
          if (_0x6469x74 == 1) {
            _0x6469x6f(_0x6469x7c + " .iwabasic-btn")["css"](
              "border-radius",
              "4px"
            );
            _0x6469x6f(_0x6469x7c)["css"]("border-radius", "5px");
          } else {
            _0x6469x6f(_0x6469x7c + " .iwabasic-btn")["css"](
              "border-radius",
              "0px"
            );
            _0x6469x6f(_0x6469x7c)["css"]("border-radius", "0px");
          }
        }
      }
    }

    function iwaHexToRgb(_0x6469x7e) {
      var _0x6469x7f = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i["exec"](
        _0x6469x7e
      );
      return _0x6469x7f
        ? {
            r: parseInt(_0x6469x7f[1], 16),
            g: parseInt(_0x6469x7f[2], 16),
            b: parseInt(_0x6469x7f[3], 16),
          }
        : null;
    }

    function iwaRgbToHex(_0x6469x81, _0x6469x82, _0x6469x83) {
      return (
        "#" +
        ((1 << 24) + (_0x6469x81 << 16) + (_0x6469x82 << 8) + _0x6469x83)
          .toString(16)
          ["slice"](1)
      );
    }

    function isMobileTablet() {
      var _0x6469x85 =
        window["screen"]["width"] < window["screen"]["height"]
          ? window["screen"]["width"]
          : window["screen"]["height"];
      if (_0x6469x85 < 500) {
        return false;
      } else {
        return true;
      }
    }
    window["isIwaMobileDevice"] = function () {
      var _0x6469x86 = false;
      (function (_0x6469x87) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i[
            "test"
          ](_0x6469x87) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i[
            "test"
          ](_0x6469x87["substr"](0, 4))
        ) {
          _0x6469x86 = true;
        }
      })(navigator["userAgent"] || navigator["vendor"] || window["opera"]);
      return _0x6469x86;
    };

    loadIWAjQuery();
  }
}

export default IwaLead;
