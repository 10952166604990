import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";
import { Flex, Box } from "@rebass/grid/emotion";

import { breadcrumbs } from "shared/helpers/schema";

import Card from "../../components/Card";
import Hero from "../../components/Hero";
import Page from "../../components/Page";
import Kranicon from "../../components/Kranicon";
import ProfileImage from "../../components/ProfileImage";
import IwaLead from "../../components/IwaLead";
import { LinkButton } from "../../components/Button";
import PageBlock from "../../components/PageBlock";

const getSlug = (text) =>
  text.toLowerCase().split(" ").join("-").replace(/\?/g, "");

const toolQuestions = [
  {
    title: "Ist diese Immobilienbewertung wirklich kostenlos?",
    body: (
      <p style={{ margin: 0 }}>
        Natürlich! Sie können unsere Immobilienbewertung kostenlos und
        unverbindlich nutzen. Gerne würden wir Ihnen auch darüber hinaus unseren
        Service als Immobilienmakler anbieten, dies ist aber kein Muss. Wir
        drängen uns Ihnen nach der Wertermittlung nicht auf.
      </p>
    ),
  },
  {
    title: "Welche Vorteile bietet die Online Immobilienbewertung?",
    body: (
      <>
        <p>
          Ein umfangreiches Gutachten von Immobilien kann durchaus einen
          vierstelligen Betrag kosten. Dies ist in den meisten Fällen jedoch
          nicht notwendig.
        </p>
        <p>
          Für Auskünfte über den aktuellen Marktpreis einer Immobilie sind
          Makler die besten Ansprechpartner.
        </p>
        <p>
          Unsere Online Immobilienbewertung ist eine kostenlose
          Ersteinschätzung, mit deren Hilfe Sie schnell planen und zusätzlich
          Ressourcen einsparen können.
        </p>
        <p>Unsere Bewertung hat zahlreiche Vorteile</p>
        <ul style={{ marginBottom: 0 }}>
          <li>schnell und einfach</li>
          <li>kostenfrei und unverbindlich</li>
          <li>kompetente Beratung durch einen Profi</li>
          <li>zeitsparend ohne Besichtigung vor Ort</li>
          <li>ist eine wichtige Vorarbeit für den Immobilienverkauf</li>
        </ul>
      </>
    ),
  },
  {
    title: "Mensch oder Maschine: Wer ermittelt den Wert?",
    body: (
      <p style={{ margin: 0 }}>
        Die Übertragung Ihrer Informationen erfolgt digital. Dennoch wird der
        Immobilienpreis von einem <strong>erfahrenen Makler</strong> mithilfe
        einer speziellen Software ermittelt. Hinter jeder Wertermittlung steckt
        ein <strong>echter Profi</strong> und nicht nur ein Algorithmus.
      </p>
    ),
  },
  {
    title: "Wie lange dauert es, bis ich meinen Immobilienpreis erhalte?",
    body: (
      <p style={{ margin: 0 }}>
        Wir bemühen uns, die Wertermittlung so schnell wie möglich
        durchzuführen. Doch{" "}
        <strong>Qualität hat bei uns höchste Priorität</strong>. Planen Sie
        deshalb eine Wartezeit von 1 bis 2 Werktagen ein.
      </p>
    ),
  },
  {
    title: "Ist die Übertragung meiner Daten sicher?",
    body: (
      <p style={{ margin: 0 }}>
        Datenschutz ist für uns enorm wichtig. Alle Daten werden von uns{" "}
        <strong>vertraulich behandelt</strong>. Die Übertragung der Daten
        erfolgt natürlich SSL-verschlüsselt.
      </p>
    ),
  },
];

const processQuestions = [
  {
    title: "Was passiert, nachdem ich das Tool benutzt habe?",
    body: (
      <>
        <p>
          Zunächst werden alle Informationen, die Sie uns zur Verfügung gestellt
          haben, in unsere spezielle Software übertragen. Diese Software ist
          ausschließlich für die{" "}
          <strong>Ermittlung von Immobilienpreisen</strong> konzipiert worden.
        </p>
        <p>
          Danach werden Ihre Informationen mit weiteren Daten{" "}
          <strong>durch einen erfahrenen Makler ergänzt</strong>. Beispielweise
          wird der Bodenrichtwert hinzugefügt.
        </p>
        <p style={{ margin: 0 }}>
          Sobald alle wichtigen Daten vorliegen, wird der Verkehrswert (Sach-
          oder Ertragswert) ermittelt. Am Ende der Ermittlung wird eine
          aussagekräftige Immobilien-Wert-Analyse aufbereitet.
        </p>
      </>
    ),
  },
  {
    title: "Wie wird der Wert eines Hauses oder Wohnung ermittelt?",
    body: (
      <>
        <p>
          Kranich Immobilien ermittelt den Wert einer Immobilie durch eine
          Wohnmarktanalyse auf Grundlage solider Daten. Dabei ermitteln wir den
          optimalen Marktwert anhand vergleichbarer Immobilien in der
          mittelbaren Umgebung. Dank unserer großen Anzahl an vergleichbarer
          Immobilien verfügt Kranich Immobilien über eine breite Palette von
          Daten und Preise, um den Immobilienmarkt genau zu analysieren und den
          optimalen Verkaufspreis zu bestimmen.
        </p>
      </>
    ),
  },
  {
    title: "Wie wird mir der Wert meiner Immobilie übermittelt?",
    body: (
      <>
        <p>
          Das Ergebnis der Immobilienwertermittlung erfolgt in der Regel
          telefonisch. In den allermeisten Fällen haben Immobilienbesitzer
          weiterführende Fragen zu unseren Ergebnissen, die wir auf diesem Weg
          sofort und kompetent beantworten können.
        </p>
        <p style={{ margin: 0 }}>
          Falls es von Ihnen gewünscht wird, können wir Ihnen das Ergebnis der
          Immobilienbewertung auch per E-Mail zukommen lassen.
        </p>
      </>
    ),
  },
];

const generalQuestions = [
  {
    title: "Warum ist die Immobilienbewertung wichtig?",
    body: (
      <>
        <p>
          Alle Immobilienverkäufer müssen sich zwangsläufig die Frage stellen,
          für welchen Preis das Objekt verkauft werden soll. Je nach Charakter
          und Gefühlslage wird der Angebotspreis entweder viel zu hoch oder gar
          viel zu niedrig angesetzt. Gerade bei privaten Verkäufern wird die
          Preisfindung zu sehr von Emotionen geleitet.
        </p>
        <p>
          Dies führt zwangsläufig zu großen Problemen beim anschließenden
          Verkauf der Immobilie.
        </p>
        <p>
          Ist der Preis zu niedrig angesetzt, so geht dem Verkäufer unweigerlich
          Vermögen verloren. Wird der Preis jedoch zu hoch angesetzt, wird der
          Immobilienverkauf zwangsläufig zu einem nie endenden Alptraum.
        </p>
        <p style={{ margin: 0 }}>
          Eine professionelle Immobilienbewertung hilft dabei, diese Probleme zu
          umgehen. Dabei erhalten Sie einen marktgerechten Preis für Ihre
          Immobilie.
        </p>
      </>
    ),
  },
  {
    title:
      "Welche Faktoren sind bei der Wertermittlung einer Immobilie wichtig?",
    body: (
      <>
        <p>
          Der Wert einer Immobilie ist von verschiedenen Faktoren abhängig. Ganz
          gleich, ob es sich um ein Einfamilienhaus, eine Wohnung oder “nur” ein
          Grundstück handelt. Die wichtigsten Faktoren für die Wertermittlung
          wären:
        </p>
        <h4>Die Immobilie</h4>
        <p>
          Bei der Bestimmung des Immobilienpreises steht natürlich die Immobilie
          selbst im Fokus.
        </p>
        <ul>
          <li>Baujahr der Immobilie</li>
          <li>Art der Modernisierungen</li>
          <li>Sanierungszustand</li>
          <li>Größe der Wohnfläche</li>
          <li>Größe der Grundstücksfläche</li>
          <li>Zustand und Beschaffenheit</li>
          <li>Lasten und Beschränkungen</li>
        </ul>
        <h4>Die Bauweise</h4>
        <p>
          Die Qualität der Bauweise beeinflusst die Immobilienbewertung
          maßgeblich. Folgende Faktoren sind hierbei wichtig:
        </p>
        <ul>
          <li>Mauerwerk</li>
          <li>Dach</li>
          <li>Dämmung</li>
          <li>Fenster</li>
          <li>Bodenbeläge</li>
          <li>Heizung</li>
          <li>Sanitärinstallationen</li>
          <li>Raumaufteilung</li>
          <li>Belichtung/Sonnenlicht</li>
          <li>Aussicht</li>
        </ul>
        <h4>Die Lage der Immobilie</h4>
        <p>
          Eine gute Immobilienbewertung kommt ohne die Berücksichtigung der Lage
          nicht aus. Der Standort der Immobilie nimmt deshalb einen wichtigen
          Platz bei der Beurteilung ein. Bei der Lage sind das die wichtigsten
          Punkte:
        </p>
        <ul style={{ marginBottom: 0 }}>
          <li>Infrastruktur</li>
          <li>Verkehrsanbindung</li>
          <li>Straßenbild</li>
          <li>Grün- und Freiflächen</li>
        </ul>
      </>
    ),
  },
  {
    title:
      "Gibt es einen Unterschied zwischen der online Immobilienbewertung und einem Wertgutachten?",
    body: (
      <>
        <p>
          Die online Immobilienbewertung gibt Ihnen die Möglichkeit schnell
          einen konkreten und präzisen Wert Ihrer Immobilie zu erhalten. Je
          detaillierter Ihre Angaben sind, desto besser können wir die Immobilie
          einschätzen und vergleichen. Den Immobilienwert, den Sie von uns
          erhalten, ist optimal für den Immobilienverkauf, aber auch für den
          Kauf von Immobilien wie Eigentumswohnungen und Einfamilienhäuser
          geeignet.
          <br />
          <br />
          Ein Wertgutachten ist unsere Einschätzung jedoch nicht. Ein
          Wertgutachten wird nur in den seltensten Fällen benötigt. Das
          Wertgutachten eines Sachverständigen ist erst dann sinnvoll, wenn es
          beispielsweise um den Bereich der Rechtsstreitigkeiten geht (Scheidung
          oder Erbe).
          <br />
          <br />
          Natürlich können Eigentümer, die Ihre Immobilie verkaufen möchten,
          auch vorab ein Wertgutachten erstellen lassen. Dadurch entstehen
          jedoch vermeidbare Kosten und dieses Verfahren nimmt deutlich mehr
          Zeit in Anspruch.
        </p>
      </>
    ),
  },
  {
    title: "Wann sollte ich einen Sachverständigen vor Ort beauftragen?",
    body: (
      <>
        <p>
          In sehr wenigen Fällen ist es dennoch nötig, eine Wertermittlung durch
          einen Sachverständigen vor Ort durchführen zu lassen. In diesen Fällen
          ist ein solches Gutachten nötig:
        </p>
        <ul style={{ marginBottom: 0 }}>
          <li>
            Durch einen Rechtsstreit benötigen Sie ein rechtssicheres Gutachten.
          </li>
          <li>
            Sie möchten eine ganz spezielle Immobilie verkaufen, die durch
            extreme Besonderheiten nur schwer zu bewerten ist.
          </li>
        </ul>
      </>
    ),
  },
];

const Immobilienbewertung = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.homestagingImage.fluid}
        icon={<Kranicon icon="leistungen" />}
        title="Professionelle Immobilienbewertung kostenlos für Thüringen"
        intro="Kennen Sie den Wert Ihrer Immobilien? Als Eigentümer sollten Sie diesen ganz genau kennen! Nur so lässt sich der optimale Verkaufspreis festlegen. Kranich Immobilien bewertet mithilfe seines Online-Tools präzise und kostenlos den Wert Ihrer Immobilie in Thüringen. Probieren Sie es aus!"
        benefits={[
          "100% kostenlos",
          "zuverlässige Ergebnisse",
          "schnell & unkompliziert",
        ]}
      />
    }
  >
    <Helmet
      title="Immobilienbewertung - Jetzt kostenlos Immobilie 🏡 bewerten"
      meta={[
        {
          name: "description",
          content:
            "Lassen Sie jetzt Ihre Immobilie von Kranich Immobilien bewerten. ✅ 100 % kostenlos  ✅ zuverlässige Ergebnisse ✅ schnell & unkompliziert ✅ Makler geprüft",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Wir bewerten Ihre Immobilie",
              link: "/immobilienbewertung/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, 1 / 2]} my={5} px={3}>
          <h2>
            Nutzen Sie jetzt unsere online Immobilienbewertung, um den richtigen
            Wert für Ihre Immobilie zu erfahren.
          </h2>
          <div id="bewertung">
            <IwaLead iwaKey="QNS465!DV93!XYF892;IE43$" />
          </div>
          <h3>Das Wichtigste in Kürze:</h3>
          <p>
            Mit unserem Tool können Sie eine kostenlose Immobilienbewertung für
            Ihre Immobilie in Thüringen erhalten. Ganz gleich, ob Sie ein Haus,
            eine Wohnung oder ein Grundstück bewerten möchten.
          </p>
          <p>
            Der Marktwert Ihrer Immobilie wird bei Kranich Immobilien durch die
            Lage, die Größe, den Zustand und die Ausstattung des Gebäudes
            bestimmt.
          </p>
          <p>
            Die wichtigsten Gründe, seine Immobilie in Thüringen online von
            Kranich Immobilien bewerten zu lassen: Sie möchten Ihre Immobilie
            verkaufen, eine Immobilie kaufen oder den Hauswert im Auge behalten.
          </p>
          <h3>
            Es gibt immer gute Gründe dafür, seine Immobilie bei Kranich
            Immobilien kostenlos bewerten zu lassen:
          </h3>
          <h4>Immobilienverkauf</h4>
          <p>
            Es ist wichtig, dass Sie den Marktwert Ihrer Immobilie kennen, wenn
            Sie sie verkaufen möchten. Auf diese Weise können Verkäufer, die
            (noch) keinen Makler haben, vermeiden, ihre Immobilie mit Verlust
            anzubieten. Auch die Möglichkeit, eine Immobilie weit unter ihrem
            Wert auf den Markt zu bringen, kann vermieden werden. Wenn Sie eine
            Immobilie weit über ihrem Wert auf den Markt bringen, könnte dies
            den Käufer abschrecken und ein erfolgreicher Verkauf kann nicht
            vollzogen werden.
          </p>
          <h4>Kauf einer Immobilie</h4>
          <p>
            Wenn es um die Entscheidung geht, ein Haus zu kaufen oder nicht, ist
            das erste Kriterium oft der Preis. Es ist möglich, dass Immobilien
            in begehrten Lagen zu einem überhöhten Preis verkauft werden. Das
            lässt sich mit unserer kostenlosen Immobilienbewertung schnell und
            einfach herausfinden. Andererseits ist es auch denkbar, dass eine
            Immobilie zu einem Schnäppchen angeboten wird. Mit unserem
            Bewertungstool bringen wir für Sie Licht ins Dunkle.
          </p>
          <h3>
            In 7 Schritten zum Ergebnis: Wie Sie unsere kostenlose
            Immobilienbewertung für Immobilien in Thüringen nutzen:
          </h3>
          <p>
            Kranich Immobilien ist Ihr Immobilienexperte, wenn es um Immobilien
            in Thüringen geht. In 7 einfachen Schritten erhalten Sie schnell
            einen präzisen Wert Ihrer Immobilie.
          </p>
          <ul
            css={css`
              list-style-type: "✓  ";
            `}
          >
            <li>
              Wählen Sie die Art Ihrer Immobilie aus. Neben herkömmlichen
              Wohnformen wie Eigentumswohnungen und Einfamilienhäusern können
              Sie sich auch für Fachwerkhäuser, Doppelhaushälften, Grundstücke
              und Gewerbeeinheiten entscheiden.
            </li>
            <li>
              Wir benötigen jetzt die Angaben zu Ihrer Immobilie und deren
              Zustand. Diese Daten werden benötigt, um vergleichbare Immobilien
              in der Region zu ermitteln. Diese werden anschließend mit anderen
              Faktoren verglichen. Außerdem erkundigen wir uns nach der Anzahl
              der Stockwerke, ob es einen Keller gibt und ob der Dachboden
              ausgebaut wurde.
            </li>
            <li>
              Die Qualität der Ausstattung ist ein wichtiger Faktor bei der
              kostenlosen Immobilienbewertung. Bewerten Sie die Ausstattung
              deshalb bitte ehrlich. Das Mauerwerk, das Dach, die
              Gebäudeisolierung sowie die vorhandenen Fenster, Bodenbeläge,
              Heizungs- und Sanitäranlagen sind für uns entscheidend. Wählen Sie
              ganz einfach zwischen "einfach", "durchschnittlich",
              "überdurchschnittlich" oder "luxuriös", um die Qualität zu
              beschreiben.
            </li>
            <li>
              Wurden seit dem Bau des Hauses irgendwelche Änderungen bzw.
              Modernisierungen vorgenommen? In der vierten Phase der
              Immobilienbewertung fragen wir nach, wann verschiedene
              Verbesserungen vorgenommen wurden. Wir haben natürlich auch hier
              eine Vorauswahl getroffen, um Ihnen die Auswahl zu erleichtern.
            </li>
            <li>
              Im fünften Schritt geben Sie uns Informationen zum Grundstück und
              Lage der Immobilie.
            </li>
            <li>
              Schritt Sechs bietet Platz, um uns Punkte zu nennen, die den Wert
              zusätzlich positiv oder negativ beeinflussen können. Gibt es
              beispielsweise Garagen oder andere Ausstattungen wie einen Pool?
              Oder gibt es Schäden, die den Wert eventuell mindern könnten?
            </li>
            <li>
              Sie haben es fast geschafft. Im letzten Schritt geben Sie Ihren
              Namen sowie Ihre E-Mail-Adresse an. Diese Daten benötigen wir, um
              Ihnen das Ergebnis unserer Bewertung mitteilen zu können.
            </li>
          </ul>
          <Box mb={5}>
            <h3>Fragen rund um unser Bewertungstool</h3>
            <ol>
              {toolQuestions.map((question) => (
                <li key={question.title}>
                  <a href={`#${getSlug(question.title)}`}>{question.title}</a>
                </li>
              ))}
            </ol>
          </Box>
          <Box mb={5}>
            <h3>Fragen zum Prozess der Wertermittlung</h3>
            <ol>
              {processQuestions.map((question) => (
                <li key={question.title}>
                  <a href={`#${getSlug(question.title)}`}>{question.title}</a>
                </li>
              ))}
            </ol>
          </Box>
          <Box mb={5}>
            <h3>Allgemeine Fragen zur Immobilienbewertung</h3>
            <ol>
              {generalQuestions.map((question) => (
                <li key={question.title}>
                  <a href={`#${getSlug(question.title)}`}>{question.title}</a>
                </li>
              ))}
            </ol>
          </Box>
          <Box mb={5}>
            <h2>Fragen rund um unser Bewertungstool</h2>
            {toolQuestions.map((question, index) => (
              <Card key={question.title} mx={-40} mb={4} collapsed>
                <h3 id={`${getSlug(question.title)}`}>
                  {index + 1}. {question.title}
                </h3>
                {question.body}
              </Card>
            ))}
          </Box>{" "}
          <Box mb={5}>
            <h2>Fragen zum Prozess der Wertermittlung</h2>
            {processQuestions.map((question, index) => (
              <Card key={question.title} collapsed mx={-40} mb={4}>
                <h3 id={`${getSlug(question.title)}`}>
                  {index + 1}. {question.title}
                </h3>
                {question.body}
              </Card>
            ))}
          </Box>
          <Box mb={5}>
            <h2>Allgemeine Fragen zur Immobilienbewertung</h2>
            {generalQuestions.map((question, index) => (
              <Card key={question.title} collapsed mx={-40} mb={4}>
                <h3 id={`${getSlug(question.title)}`}>
                  {index + 1}. {question.title}
                </h3>
                {question.body}
              </Card>
            ))}
          </Box>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, 3 / 4]}>
          <Card>
            <Flex alignItems="center" flexWrap="wrap">
              <Box width={[1, 1, 1, 1 / 3]} pr={[0, 0, 0, 4]} mt="-20px">
                <ProfileImage />
              </Box>
              <Box width={[1, 1, 1, 2 / 3]} px={3}>
                <h2>Gerne helfen wir Ihnen weiter</h2>
                <p>
                  Sie haben weitere Fragen zur Immobilienbewertung oder möchten
                  mit uns über Ihre Immobilie sprechen? Gerne stehen wir Ihnen
                  kompetent und freundlich zur Verfügung.
                </p>
                <ul
                  css={css`
                    list-style-type: "✓  ";
                  `}
                >
                  <li>Kompetente und professionelle Rund­um­be­treu­ung</li>
                  <li>Detaillierte und transparente Arbeitsweise</li>
                  <li>Ehrlicher und freundlicher Umgang</li>
                  <li>Flexible Lösungen individueller Probleme</li>
                </ul>
                <LinkButton to="/kontakt/">
                  Sprechen Sie mit mir über Ihre Immobilie
                </LinkButton>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Flex>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    homestagingImage: imageSharp(
      fluid: { originalName: { regex: "/homestagingHero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    homestagingLivingRoom: imageSharp(
      fluid: { originalName: { regex: "/homestagingLivingRoom/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    homestagingCouch: imageSharp(
      fluid: { originalName: { regex: "/homestagingCouch/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    homestagingKitchen: imageSharp(
      fluid: { originalName: { regex: "/homestagingKitchen/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Immobilienbewertung;
